import React from 'react';

enum IconType {
  BRAND = 'fab fa-',
  NORMAL = 'fal fa-',
}

enum BtnType {
  INLINE = 'inline-button',
  NORMAL = 'button',
  IMPORTANT = 'button button-main',
}

type ButtonProps = {
  url: string;
  external?: boolean;
  btnType?: string;
  icon?: string;
  brandIcon: boolean;
  text: string;
};

const Button = ({
  url,
  external,
  btnType,
  icon,
  brandIcon,
  text,
}: ButtonProps) => {
  return (
    <a
      href={url}
      target={'_blank'}
      rel={'noreferrer noopener'}
      className={
        btnType === 'inline'
          ? BtnType.INLINE
          : btnType === 'important'
            ? BtnType.IMPORTANT
            : BtnType.NORMAL
      }
    >
      {icon ? (
        <i
          className={`${brandIcon ? IconType.BRAND : IconType.NORMAL}${icon}`}
        />
      ) : null}
      {text}
    </a>
  );
};

Button.defaultProps = {
  brandIcon: false,
  external: false,
};

export default Button;

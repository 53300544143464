import { TProject } from '../modules/Project';
import { Badges } from './styled/Badge';

type TProjectMeta = {
  proj: TProject;
};

const ProjectMeta = ({ proj }: TProjectMeta) => {
  return (
    <>
      {Object(proj).hasOwnProperty('labels') && (
        <>
          <h5 style={{ margin: 0, opacity: 0.7, fontStyle: 'italic' }}>
            Labels
          </h5>
          <Badges className={'akm-badge'}>
            {proj.labels?.map((val, index) => <li key={index}>{val.label}</li>)}
          </Badges>
        </>
      )}
      {Object(proj).hasOwnProperty('languages') && (
        <>
          <h5 style={{ margin: 0, opacity: 0.7, fontStyle: 'italic' }}>
            Languages
          </h5>
          <Badges className={'akm-badge'}>
            {proj.languages?.map((val, index) => (
              <li key={index}>{val.label}</li>
            ))}
          </Badges>
        </>
      )}
      {Object(proj).hasOwnProperty('frameworks') && (
        <>
          <h5 style={{ margin: 0, opacity: 0.7, fontStyle: 'italic' }}>
            Frameworks & Libraries
          </h5>
          <Badges className={'akm-badge'}>
            {proj.frameworks?.map((val, index) => (
              <li key={index}>{val.label}</li>
            ))}
          </Badges>
        </>
      )}
    </>
  );
};

export default ProjectMeta;

import React from 'react';

type EmailProps = {
  email: string;
};

export interface EmailContactProps {
  email: string;
}

const EmailContact: React.FC<EmailContactProps> = ({ email }) => (
  <>
    <p>
      Email me: <a href={`mailto: ${email}`}>{email}</a>
      <br />
      <br />
    </p>
  </>
);

export default EmailContact;

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// styles
import './components/assets/styles/main.scss';

// images
import appLogo from './components/assets/images/big-logo.svg';
import smallLogo from './components/assets/images/small-logo.svg';

// pages
import AppNavigation from './components/pages/Navigation';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Footer from './components/pages/Footer';


const App = () => {
  return (
    <>
      <AppNavigation logo={appLogo} />
      <Router>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route exact path={`/about`} component={About} />
        </Switch>
      </Router>
      <Footer footerPic={smallLogo} picAlt="Andrew Krause Logo small" />
      </>

  );
};

export default App;
